import { Routes, Route, Navigate } from "react-router-dom";
// import Edit from "./Edit";
// import SharedEditEmbed from "./SharedEditEmbed";
// import Project from "./Project";
// import EditEmbed from "./EditEmbed";
import Article from "./Article";
import Search from "./Search";
// import { Search } from "react-notion-x";

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Search />} />
      {/* <Route path="/article/:id" element={<Project />} /> */}
      {/* <Route path="/shared/:guid" element={<SharedEditEmbed />} /> */}
      <Route path="/:id" element={<Article />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
export default MainRoutes;
