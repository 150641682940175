import { FC, Fragment, useEffect, useState } from "react";
import { NotionRenderer } from "react-notion-x";
import { useAuthenticatedQuery } from "./Authenticator";
// core styles shared by all of react-notion-x (required)
import "./notion.css";

// used for code syntax highlighting (optional)
import "prismjs/themes/prism-tomorrow.css";

// used for rendering equations (optional)
import "katex/dist/katex.min.css";
import { Link, useParams } from "react-router-dom";
import SidebarPortal from "./SidebarPortal";
// const pageId = "6449c5a960934fb0a0a6820dabdb58ea";
const Article: FC = () => {
  const { id } = useParams();
  const { data } = useAuthenticatedQuery<{ page: any }>(`/page/${id}`);
  const recordMap = data?.page;
  useEffect(() => {
    if (data) {
      if (location.hash) {
        //scroll to position
        const element = document.getElementById(location.hash.substring(1));
        if (element) {
          element.scrollIntoView();
        }
      }
    }
  }, [data]);
  let titles = undefined;
  if (recordMap) {
    console.log("recordmap is", recordMap);
    console.log("my rtcordmap is ", Object.entries(recordMap.block)[0][1]);
    titles = Object.entries(recordMap.block)
      .filter(
        ([id, block]) =>
          (block as { value: { type?: string } }).value.type == "sub_sub_header"
      )
      .map(([id, block]) => {
        return {
          id: id.replaceAll("-", ""),
          title: (
            block as { value: { properties: { title?: string[] } } }
          ).value.properties.title.join(" "),
        };
      });
    console.log("titles", titles);
  }
  return (
    <div>
      <SidebarPortal>
        <div className="flex flex-col">
          {titles && (
            <Fragment>
              <h2 className="text-white text-sm font-bold py-2 pl-2">
                Contents
              </h2>
              {titles.map(({ id, title }) => (
                <a
                  className="text-white hover:text-blue-200 text-xs pb-2 pl-2"
                  href={`#${id}`}
                >
                  {title}
                </a>
              ))}
            </Fragment>
          )}
        </div>
      </SidebarPortal>
      {!recordMap && (
        <h1 className="text-lg text-green-500 animate-pulse">Loading</h1>
      )}
      {recordMap && (
        <NotionRenderer
          recordMap={recordMap}
          fullPage={true}
          darkMode={true}
          disableHeader={true}
          // pageCover={false}
          className="bg-gray-900"
        />
      )}
    </div>
  );
};
export default Article;
