import { FC } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Authenticator from "./Authenticator";
import Login from "./Login";
import Signup from "./Signup";
import AlertProvider from "./Alert";
// import SharedHome from "./SharedHome";
import ModalPicker from "./ModalPicker";
import Home from "./Home";

const App: FC = () => {
  return (
    <BrowserRouter>
      <AlertProvider>
        <ModalPicker>
          <Authenticator
            fallback={
              <Routes>
                <Route path="/signup" element={<Signup />} />
                <Route path="*" element={<Login />} />
                {/* <Route path="/shared/:guid" element={<SharedHome />} /> */}
              </Routes>
            }
          >
            <Home />
          </Authenticator>
        </ModalPicker>
      </AlertProvider>

      <ToastContainer hideProgressBar position="bottom-right" />
    </BrowserRouter>
  );
};

export default App;
