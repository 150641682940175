import { Field, Formik, Form } from "formik";
import { FC, Fragment, useCallback, useState } from "react";
import { useAuthenticatedFetch } from "./Authenticator";
import { toast } from "react-toastify";
import { useAlert } from "./Alert";
import { useNavigate } from "react-router-dom";
type ArticleType = {
  id: string;
  cover: string;
  title: string;
};
const Search: FC = () => {
  const navigate = useNavigate();
  const fetch = useAuthenticatedFetch();
  const { alert } = useAlert();
  const [articles, setArticles] = useState<ArticleType[]>([]);
  const doSearch = useCallback(
    async (q: string) => {
      const response = await fetch(`/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ q }),
      });
      if (response.status !== 200) {
        toast.error("Something went wrong");
      } else {
        setArticles(await response.json());
      }
    },
    [fetch, alert]
  );
  //   return <NotionSearch  />;
  return (
    <div>
      <Formik
        initialValues={{
          search: new URLSearchParams(window.location.search).get("q") || "",
        }}
        validate={({ search }) => {
          navigate("/?q=" + search, { replace: true });
          if (!search) {
            return { search: "Search is required" };
          }
        }}
        onSubmit={async ({ search }) => {
          await doSearch(search);
        }}
      >
        {({ isSubmitting, values }) => (
          <Fragment>
            <Form>
              <div>
                {/* <label
                  htmlFor="search"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Search
                </label> */}
                <div className="mt-2">
                  <Field
                    name="search"
                    id="search"
                    className="block p-2  w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xl sm:leading-6"
                    placeholder="Search for..."
                    autoFocus
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        doSearch(e.currentTarget.value);
                      }
                    }}
                  />
                </div>
                {!isSubmitting && !!values.search.length && (
                  <button
                    disabled={isSubmitting}
                    className={
                      isSubmitting
                        ? "bg-gray-600 text-white px-4 py-2 rounded-md"
                        : "bg-blue-600 text-white px-4 py-2 rounded-md"
                    }
                    type="submit"
                  >
                    Search for {values.search}
                  </button>
                )}
              </div>
            </Form>

            {isSubmitting && (
              <div className="text-xl animate animate-pulse text-white">
                Searching for {values.search}...
              </div>
            )}
            {!!values.search.length && !isSubmitting && !articles.length && (
              <div className="text-xl text-white">No results</div>
            )}
            {!!articles && !!articles.length && (
              <div>
                <div className="text-white">
                  Found {articles.length} result{articles.length > 1 && "s"}
                </div>
                <ul
                  role="list"
                  className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8"
                >
                  {articles &&
                    articles.map((article) => <Article article={article} />)}
                </ul>
              </div>
            )}
          </Fragment>
        )}
      </Formik>
    </div>
  );
};
export default Search;

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
const files = [
  {
    title: "IMG_4985.HEIC",
    size: "3.9 MB",
    source:
      "https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
  },
  // More files...
];

const Article: FC<{ article: ArticleType }> = ({ article }) => {
  console.log(article);
  return (
    <li key={article.page_id} className="relative bg-gray-100 p-5">
      <div className=" group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
        <img
          src={article.cover}
          alt=""
          className="pointer-events-none object-cover group-hover:opacity-75"
        />
        <a
          type="button"
          className="absolute inset-0 focus:outline-none"
          href={`/${article.page_id}`}
        >
          <span className="sr-only">View details for {article.title}</span>
        </a>
      </div>
      <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
        {article.title}
      </p>
    </li>
  );
};
